var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":"mdi-update","title":"DB Changes","color":"primary"}},[_c('v-row',{staticClass:"mb-3",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-1",attrs:{"color":"primary","fab":"","small":"","loading":_vm.loadingAll},on:{"click":_vm.exportToPdf}},on),[_c('v-icon',[_vm._v("mdi-file-pdf-outline")])],1)]}}])},[_c('span',[_vm._v("Export Full Report to PDF")])]),_c('download-csv',{attrs:{"data":_vm.totalItems,"name":"DB Changes.csv"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-1",attrs:{"color":"secondary","fab":"","small":"","loading":_vm.loadingAll}},on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Export Full Report to CSV")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-1",attrs:{"color":"warning","fab":"","small":""},on:{"click":function($event){_vm.emailDlg = true}}},on),[_c('v-icon',[_vm._v("mdi-email")])],1)]}}])},[_c('span',[_vm._v("Email Full Report")])])],1),_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":""},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"WHEN","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{staticClass:"datepicker-width",attrs:{"no-title":"","range":"","color":"primary"},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.$refs.menu.save(_vm.dates); _vm.getDataFromApi()}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-update","label":"WHAT","items":_vm.tableNames,"item-value":"id","item-text":"name","loading":_vm.preparing},on:{"change":_vm.getDataFromApi},model:{value:(_vm.tableName),callback:function ($$v) {_vm.tableName=$$v},expression:"tableName"}})],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-update","label":"IS DONE","clearable":"","items":_vm.actions,"item-value":"id","item-text":"name","loading":_vm.preparing},on:{"change":_vm.getDataFromApi},model:{value:(_vm.action),callback:function ($$v) {_vm.action=$$v},expression:"action"}})],1)],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"options":_vm.options,"hide-default-footer":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getActionColor(item.action),"label":""}},[_vm._v(" "+_vm._s(item.action.toUpperCase())+" ")])]}}])}),_c('table-footer',{attrs:{"options":_vm.options,"total":_vm.total}}),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.emailDlg),callback:function ($$v) {_vm.emailDlg=$$v},expression:"emailDlg"}},[_c('v-card',{staticClass:"text-center"},[_c('v-card-title',[_vm._v(" Select Users to Send "),_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.productDlg = false}}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.users,"loading":_vm.fetchingUsers,"hide-no-data":"","hide-selected":"","item-text":"name","item-value":"id","clearable":"","multiple":"","label":"Select Users *","prepend-icon":"mdi-account","rules":[_vm.empty]},model:{value:(_vm.user_ids),callback:function ($$v) {_vm.user_ids=$$v},expression:"user_ids"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","type":"submit","loading":_vm.sending}},[_vm._v(" Email Full Report ")]),_c('v-btn',{attrs:{"color":"error","type":"button"},on:{"click":function($event){_vm.emailDlg = false, _vm.user_ids = []}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }